.modal {
  background: #fff;
  outline: 0;
  min-width: 250px;
  max-width: 500px;
  border-radius: 4px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 0.5em 1.5em;
}

.modal-header {
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin: 0;
}

.modal-body li {
  list-style-type: circle;
  line-height: 1.25em;
  font-size: 1em;
}

.modal-footer {
  border-top: 1px solid #eee;
  text-align: right;
}

.modal--animated {
  opacity: 0.3;
  transform: scale(1.1) translateY(-10px);
  -webkit-transform: scale(1.1) translateY(-10px);
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.modal--animated.has-entered {
  opacity: 1;
  transform: scale(1) translateY(0);
  -webkit-transform: scale(1) translateY(0);
}

.underlay {
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}

.underlay.has-entered {
  background-color: rgba(0, 0, 0, 0.5);
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}
